<template>
  <div class="assoUser bg-white">
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <!-- <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button> -->
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addAdmin"></Button>
        <i-input
          v-model="inputValue"
          size="large"
          style="width: 20rem"
          placeholder="输入姓名进行查找"
          class="el-inline mr-1"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="handleSearch"></Button>
        </i-input>
        <!-- <Button type="default" size="large" class="mr-1">批量导出</Button> -->
      </i-col>
      <i-col span="24">
        <Table
          border
          :loading="searching"
          context-menu
          ref="careermanTable"
          :columns="tColumns"
          :data="tData"
          class="mt-1"
        >
          <div slot-scope="{ row }" slot="action">
            <!-- <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="showAdmin(row)"
            >详情</Button>-->
            <Button
              type="default"
              size="small"
              :disabled="row.roleId == 1"
              @click="deleteAdmin(row)"
            >删除</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>

    <modal
      v-model="draw"
      footer-hide
      title="管理员信息"
      width="500"
      :mask-closable="true"
      :closable="true"
    >
      <Form :model="adminModel" :label-width="80">
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="账号：" label-position="left">
              <Input size="large" v-model="adminModel.loginName" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="姓名：" label-position="left">
              <Input size="large" v-model="adminModel.realName" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="性别：" label-position="left">
              <Input size="large" v-model="adminModel.gender == 1 ? '男' : '女'" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="手机：" label-position="left">
              <Input size="large" v-model="adminModel.bindPhone" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="权限级：" label-position="left">
              <Input size="large" v-model="adminModel.roleId" />
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </modal>
    <modal
      v-model="show"
      title="添加管理员"
      width="500"
      ok-text="添加"
      :mask-closable="false"
      :closable="true"
      @on-ok="addAdminInfo"
    >
      <Form ref="formValidate" :model="adminModel" :label-width="80" :rules="ruleValidate">
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="账号：" label-position="left" prop="loginName">
              <Input size="large" v-model="adminModel.loginName" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="姓名：" label-position="left" prop="realName">
              <Input size="large" v-model="adminModel.realName" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="手机：" label-position="left" prop="bindPhone">
              <Input size="large" v-model="adminModel.bindPhone" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="角色：" label-position="left" prop="roleId_">
              <Select v-model="adminModel.roleId_" style="width:100%" size="large">
                <Option value="2">普通管理员</Option>
                <Option value="3">心理咨询师</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div slot="footer" style="height:40px">
        <Button type="primary" size="large" style="float:right;" @click="addAdminInfo">添加</Button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "Careerman",
  data() {
    return {
      ruleValidate: {
        loginName: [
          {
            required: true,
            message: "登录账号必须填写",
            trigger: "blur",
          },
        ],
        realName: [
          {
            required: true,
            message: "姓名必须填写",
            trigger: "blur",
          },
        ],
        bindPhone: [
          {
            required: true,
            message: "手机号码必须填写",
            trigger: "blur",
          },
        ],
        roleId_: [
          {
            required: true,
            message: "必须选择角色",
            trigger: "change",
          },
        ],
      },
      show: false,
      adminModel: {},
      draw: false,
      searching: true,
      tData: [],
      inputValue: "",
      selectValue: "usersName",
      isDetach: false,
      theight: window.innerHeight - 175,
      recordTotal: 0,
      pageSize: 20,
      pageNum: 1,
      tColumns: [
        // {
        //   type: "selection",
        //   width: 35,
        //   align: "center",
        // },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "登录账号",
          key: "loginName",
          minWidth: 50,
        },
        {
          title: "姓名",
          key: "realName",
          minWidth: 80,
        },
        {
          title: "手机号码",
          key: "bindPhone",
          minWidth: 80,
        },
        {
          title: "用户角色",
          key: "roleId",
          minWidth: 80,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const color =
              row.roleId === 1
                ? "primary"
                : row.roleId === 2
                ? "success"
                : "warning";
            const text =
              row.roleId === 1
                ? "超级管理员"
                : row.roleId === 2
                ? "普通管理员"
                : "咨询师";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 107,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getAdminList();
  },
  methods: {
    addAdminInfo() {
      this.$refs["formValidate"].validate((valid) => {
        if (valid) {
          this.$post("Admin/AddAdmin", this.adminModel).then((res) => {
            console.log(res);
            if (res.data.code) {
              this.$Message.success({
                content: "添加成功",
                duration: 3,
              });
            } else {
              this.$Message.error({
                content: "添加失败，请稍后重试",
                duration: 3,
              });
            }
          });
          this.show = false;
        } else {
          this.$Message.error("请将内容输入完整!");
        }
      });
    },
    addAdmin() {
      this.show = true;
    },
    deleteAdmin(row) {
      let adminId = "" + row.adminId;
      this.$Modal.confirm({
        title: "提示",
        content: "删除后不可恢复，确认删除？",
        onOk: () => {
          this.$post("Admin/DeleteAdmin", { adminId: adminId }).then((res) => {
            if (res.data.code) {
              this.$Message.success(res.data.msg);
              this.getAdminList();
            } else {
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {},
      });
    },
    showAdmin(row) {
      this.adminModel = row;
      this.draw = true;
    },
    changeSize(e) {
      this.pageSize = e;
      this.getAdminList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getAdminList();
    },
    assessmentRecord() {},
    consultRecord() {},
    handleSearch() {
      this.pageNum = 1;
      this.getAdminList();
    },
    getAdminList() {
      (this.searching = true),
        this.$get("Admin/GetAdminList", {
          keyword: this.inputValue,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        }).then((res) => {
          this.tData = res.data.responseList;
          this.recordTotal = res.data.total;
          this.searching = false;
        });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
</style>
